<template>
    <b-modal id="modal-add-more-users" title="Add Users" no-close-on-backdrop @hide="beforeClose">
        <p class="text-title">
            Upgrade your account to add more users.
        </p>
        <p class="text-title">
            When working with teammates you can assign tasks, collaborate on diligence, and get help scouting startups.
        </p>
        <template #modal-footer>
            <b-button size="lg" variant="outline-primary" class="btn btn-bordered btn-lg" @click="hide">
                Cancel
            </b-button>
            <b-button size="lg" variant="primary" class="ml-3" @click="sendUpgradeRequest">
                Upgrade Account
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import Vue from 'vue';
import userService from '@/services/user.service';

export default {
    name: 'AddMoreUsers',
    methods: {
        show() {
            this.$bvModal.show('modal-add-more-users');
        },
        hide() {
            this.$bvModal.hide('modal-add-more-users');
        },
        beforeClose() {
            this.$bvModal.hide('modal-add-more-users');
        },
        async sendUpgradeRequest() {
            const {data} = await userService.requestAddUsers();

            if (data === true) {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: 'Request to Upgrade account has been sent to your account manager. We will contact you shortly.'
                });
                this.message = ''

            } else {
                Vue.notify({
                    group: 'app',
                    type: 'error',
                    duration: 5000,
                    text: 'Some Error occurred. Please, contact your Account Manager.'
                });

            }
            this.hide()
        }
    }
}
</script>
